import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import {
    BooleanInput,
    Button,
    Confirm,
    Edit,
    ListButton,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    TopToolbar,
    useDataProvider,
    useRecordContext,
    useRefresh
} from "react-admin";
import { useForm } from 'react-hook-form';

const ActivateInstanceButton = (props) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { record } = props;
    console.log("record", PlayArrowIcon, record);
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const icon = record && record.active ? <StopIcon /> : <PlayArrowIcon />;
    const handleConfirm = () => {
        console.log("record", record);
        dataProvider
            .activate("subscription_types", {
                data: {
                    id: record.id,
                    active: ((!record) || (record && !record.active)),
                    type: record.type,
                },
            })
            .then(() => {
                setOpen(false);
                refresh();
            });
    };

    return (
        <>
            <Button
                label={`${record && record.active ? "Deactivate" : "Activate"}`}
                onClick={handleClick}
                startIcon={icon}
            />
            <Confirm
                isOpen={open}
                title={`${record && record.active ? "Deactivate" : "Activate"
                    } subscription type`}
                content={`Are you sure you want to ${record && record.active ? "deactivate" : "activate"
                    } subscription type`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

const InstanceEditActions = ({ basePath, data, resource }) => {
    const record = useRecordContext();
    console.log("TEST", record);
    return (
        <TopToolbar>
            <ActivateInstanceButton record={record} color="primary" />

            <ListButton basePath={basePath} />
            {/*<ShowButton basePath={basePath} record={data} />*/}
            {/* Add your custom actions */}
        </TopToolbar>
    );
};

export const TenantEdit = (props) => {

    const { watch, register } = useForm();
    const [changeInput, setChangeInput] = useState(false)

    useEffect(() => {
        watch((value, { name, type }) => {
            if (type === 'change') {
                setChangeInput(true)
            }
        }
        )
    }, [watch, changeInput]);

    const UserEditToolbar = (props) => {

        return (
            <Toolbar {...props}>
                <SaveButton alwaysEnable={changeInput} />
            </Toolbar>
        )
    }
    return (
        <Edit sx={{ width: "100%" }} actions={<InstanceEditActions />} {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <Typography variant="h6" gutterBottom>
                    General
                </Typography>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={2}>
                        <TextInput source="id" disabled={true} />
                        <BooleanInput source="active" />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <TextInput
                            source="name"
                            sx={{ minWidth: 250, width: "50%" }}
                            {...register("name")}
                        />
                    </Stack>
                </Stack>
            </SimpleForm>
        </Edit>
    );
}
