// in src/users.js
import * as React from "react";
import { useEffect } from "react";
import {
  Datagrid,
  DateField,
  List, SelectInput,
  TextField,
  TextInput,
  useRefresh,
} from "react-admin";

export const UserSubscriptionList = (props) => {
  const refresh = useRefresh();
  useEffect(() => {
    const refreshInterval = 60 * 1000; // 30 seconds
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(refresh, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refresh]);

  const postFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <SelectInput
        source="status" 
        defaultValue="active"
        autoWidth={true}
        choices={[
          { id: "active", name: "Active" },
          { id: "used", name: "Used" },
          { id: "suspended", name: "Suspended" },
          { id: "pending", name: "Pending" },
          { id: "requested", name: "Requested" },
          { id: "expired", name: "Expired" }
        ]}
        sx={{ width: "25%" }}
    />
  ];

  return (
    <List
      {...props}
      pagination={false}
      sort={{ field: "user_name", order: "ASC" }}
      filters={postFilters}
    >
      <Datagrid rowClick="edit"  bulkActionButtons={false}>
        <TextField source="user_name" />
        <TextField source="id" />
        <TextField source="subscription_name" />
        <TextField source="companyName" />
        <DateField source="valid_from" locales="en-US" />
        <DateField source="valid_to" locales="en-US" />
        <TextField source="status" />
      </Datagrid>
    </List>
  );
};
