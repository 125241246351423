import { Chip, Box, Typography } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import {
    BooleanField,
    Datagrid,
    List,
    TextField,
    TextInput,
    useRefresh,
    TopToolbar,
    CreateButton,
} from "react-admin";

export const TenantList = (props) => {
    const refresh = useRefresh();
    const refreshInterval = 60 * 1000; // 30 seconds

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(refresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refresh, refreshInterval]);

    const QuickFilter = ({ label }) => {
        return <Chip sx={{ marginBottom: 1 }} label={label} />;
    };

    const postFilters = [
        <TextInput label="Search" source="q" alwaysOn />,
        <QuickFilter label="Active" source="active" defaultValue={true} />,
    ];

    const ListActions = () => (
        <TopToolbar>
            <CreateButton/>
        </TopToolbar>
    );

    const Empty = () => (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                No tenants available
            </Typography>
            <Typography variant="body1">
                Create one
            </Typography>
            <CreateButton />
        </Box>
    );


    return (
        <List
            actions={<ListActions/>}
            empty={<Empty/>}
            {...props}
            pagination={false}
            sort={{ field: "name", order: "ASC" }}
            filters={postFilters}
        >
            <Datagrid rowClick="edit">
                <TextField source="name" />
                <BooleanField source="active" />
            </Datagrid>
        </List>
    );
};
