import * as React from "react";
import {
    ListButton,
    Show,
    SimpleShowLayout,
    TopToolbar,
    useRecordContext,
    TextField
} from "react-admin";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CopyToClipboardButton from "../../common/CopyToClippboardButton";
import FSDateTimeField from "../../common/FSDateTimeField";
import TimestampDateTimeField from "../../common/TimestampDateTimeField";

const AIShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basepath={basePath} />
    </TopToolbar>
);

const Summary = () => {
    const record = useRecordContext();

    return record ? (
        <TableContainer
            sx={{
                padding: "30px",
            }}
            component={Paper}
        >
            <Table size="small">
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300, verticalAlign: 'baseline' }}>
                        Id
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField label="id" source="id" />{" "}
                        <CopyToClipboardButton source="id" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300, verticalAlign: 'baseline' }}>
                        Status
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField label="status" source="status" sx={{ textTransform: 'uppercase' }} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300, verticalAlign: 'baseline' }}>
                        Date
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TimestampDateTimeField source="timestamp" locales="en-US" emptyText="none" />
                    </TableCell>
                </TableRow>
                {record && record.expires ?
                    <TableRow>
                        <TableCell component="th" scope="row" sx={{ width: 300, verticalAlign: 'baseline' }}>
                            Expires
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <FSDateTimeField source="expires" locales="en-US" emptyText="none" />
                        </TableCell>
                    </TableRow>
                    : null
                }
                {record && record.message ?
                    <TableRow>
                        <TableCell component="th" scope="row" sx={{ width: 300, verticalAlign: 'baseline' }}>
                            Message
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <TextField label="message" source="message" />
                        </TableCell>
                    </TableRow>
                    : null
                }
                {record && record.summary && record.summary.summary ?
                    <TableRow>
                        <TableCell component="th" scope="row" sx={{ width: 300, verticalAlign: 'baseline' }}>
                            <div className="data-cell__copy-to-clipboard">
                                Summary
                                <CopyToClipboardButton string source={record.summary.summary} className="launch-link" />
                            </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <TextField label="summary" source="summary.summary" />
                        </TableCell>
                    </TableRow>
                    : null
                }
                {record.summary && record.summary.main_topics && record.summary.main_topics.length ?
                    <TableRow>
                        <TableCell component="th" scope="column" sx={{ width: 300, verticalAlign: 'baseline' }}>
                            <div className="data-cell__copy-to-clipboard">
                                Main Topics
                                <CopyToClipboardButton string source={
                                    // eslint-disable-next-line
                                    JSON.stringify(record.summary.main_topics).replace(/[\[\]\{\}]/g, '')
                                } className="launch-link" />
                            </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {record.summary.main_topics && record.summary.main_topics.length ?
                                <ul>
                                    {record.summary.main_topics.map((item, i) => {
                                        return (
                                            <li key={i}>
                                                <h4>{item.name}</h4>
                                                {item.description}
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                            }
                        </TableCell>
                    </TableRow>
                    : null
                }
                {record && record.transcript ?
                    <TableRow>
                        <TableCell component="th" scope="row" sx={{ width: 300, verticalAlign: 'baseline' }}>
                            <div className="data-cell__copy-to-clipboard">
                                Transcript
                                <CopyToClipboardButton string source={record.transcript} className="launch-link" />
                            </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <TextField component="pre" label="transcript" source="transcript" />
                        </TableCell>
                    </TableRow>
                    : null
                }
            </Table>
        </TableContainer>
    ) : (
        <div>No data for this summary</div>
    );
};

export const AIShow = (props) => {
    return (
        <Show actions={<AIShowActions />} {...props}>
            <SimpleShowLayout>
                <Summary />
            </SimpleShowLayout>
        </Show>
    );
};