import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import {
    BooleanInput,
    Button,
    Confirm,
    Edit,
    ListButton,
    NumberInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    TopToolbar,
    useDataProvider,
    useRecordContext,
    useRefresh
} from "react-admin";
import { useForm } from 'react-hook-form';

const ActivateInstanceButton = (props) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { record } = props;
    console.log("record", PlayArrowIcon, record);
    const [open, setOpen] = useState(false);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const icon = record && record.active ? <StopIcon /> : <PlayArrowIcon />;
    const handleConfirm = () => {
        console.log("record", record);
        dataProvider
            .activate("subscription_types", {
                data: {
                    id: record.id,
                    active: ((!record) || (record && !record.active)),
                    type: record.type,
                },
            })
            .then(() => {
                setOpen(false);
                refresh();
            });
    };

    return (
        <>
            <Button
                label={`${record && record.active ? "Deactivate" : "Activate"}`}
                onClick={handleClick}
                startIcon={icon}
            />
            <Confirm
                isOpen={open}
                title={`${record && record.active ? "Deactivate" : "Activate"
                    } subscription type`}
                content={`Are you sure you want to ${record && record.active ? "deactivate" : "activate"
                    } subscription type`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

const InstanceEditActions = ({ basePath, data, resource }) => {
    const record = useRecordContext();
    console.log("TEST", record);
    return (
        <TopToolbar>
            <ActivateInstanceButton record={record} color="primary" />

            <ListButton basePath={basePath} />
            {/*<ShowButton basePath={basePath} record={data} />*/}
            {/* Add your custom actions */}
        </TopToolbar>
    );
};

export const SubscriptionTypesEdit = (props) => {

    const { watch, register } = useForm();
    const [changeInput, setChangeInput] = useState(false)

    useEffect(() => {
        watch((value, { name, type }) => {
            if (type === 'change') {
                setChangeInput(true)
            }
        }
        )
    }, [watch, changeInput]);

    const UserEditToolbar = (props) => {

        return (
            <Toolbar {...props}>
                <SaveButton alwaysEnable={changeInput} />
            </Toolbar>
        )
    }
    return (
        <Edit sx={{ width: "100%" }} actions={<InstanceEditActions />} {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <Typography variant="h6" gutterBottom>
                    General
                </Typography>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={2}>
                        <TextInput source="id" disabled={true} />
                        <TextInput source="type" disabled={true} />
                        <TextInput source="version" disabled={true} />
                        <BooleanInput source="active" />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <TextInput
                            source="title"
                            sx={{ minWidth: 250, width: "50%" }}
                            {...register("title")}
                        />
                    </Stack>
                </Stack>
                <Typography variant="h6" gutterBottom>
                    Billing
                </Typography>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={2}>
                        <SelectInput
                            source="term"
                            choices={[{ id: "annual", name: "Annual" }]}
                            sx={{ width: "25%", marginTop: "0" }}
                            label={"Subscription Term"}
                            {...register("term")}
                        />
                        <SelectInput
                            source="payment"
                            autoWidth={true}
                            choices={[
                                {
                                    id: "credit_card ",
                                    name: "Overages paid by credit card ",
                                },
                                {
                                    id: "credit_card_or_invoice",
                                    name: "Credit card or invoice",
                                },
                                {
                                    id: "monthly_or_annual_invoice",
                                    name: "Monthly or Annual Invoice",
                                },
                            ]}
                            sx={{ width: "25%" }}
                            {...register("payment")}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}></Stack>
                </Stack>
                <Typography variant="h6" gutterBottom>
                    Account
                </Typography>
                <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <BooleanInput source="sso" label={"Single sign-on"} />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <NumberInput
                        source="concurrent_events"
                        step={1}
                        resettable={true}
                        {...register("concurrent_events")}
                    />
                    <SelectInput
                        source="duration_overage"
                        required={true}
                        autoWidth={true}
                        displayEmpty={false}
                        choices={[
                            {
                                id: "duration_overage_1",
                                name: "Subtracted from quarterly allowance or $100 if allowance used",
                            },
                            {
                                id: "duration_overage_2",
                                name: "Subtracted from annual allowance",
                            },
                            { id: "duration_overage_3", name: "Unlimited" },
                        ]}
                        sx={{ width: "25%" }}
                        {...register("duration_overage")}
                    />
                </Stack>
                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                    <NumberInput
                        source="max_event_hours"
                        step={1}
                        resettable={true}
                        sx={{ width: "25%" }}
                        {...register("max_event_hours")}
                    />
                    <SelectInput
                        source="max_event_hours_period"
                        required={false}
                        allowEmpty={true}
                        autoWidth={true}
                        choices={[
                            { id: "quarter", name: "Quarter" },
                            { id: "year", name: "Year" },
                        ]}
                        sx={{ width: "25%" }}
                        {...register("max_event_hours_period")}
                    />
                    <NumberInput
                        source="extra_event_hours"
                        step={1}
                        resettable={true}
                        sx={{ width: "25%" }}
                        {...register("extra_event_hours")}
                    />
                </Stack>
                <Typography variant="h6" gutterBottom>
                    Event
                </Typography>
                <Stack direction="column" xs={{ width: "50%", marginTop: "0" }}>
                    <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                        <SelectInput
                            source="event_brending"
                            choices={[
                                { id: "logo", name: "Corporate logo" },
                                {
                                    id: "event_card_lobby",
                                    name: "Branding in Event Card and Audience Lobby",
                                },
                            ]}
                            sx={{
                                width: "50%",
                                marginTop: "0",
                            }}
                            {...register("event_brending")}
                        />
                        <SelectInput
                            source="pre_event_rehearsal_min"
                            defaultValue={30}
                            emptyText={"Please select some value"}
                            allowEmpty={true}
                            required={true}
                            autoWidth={true}
                            choices={[
                                { id: "30", name: "30 minutes" },
                                { id: "-1", name: "Unlimited" },
                            ]}
                            sx={{ width: "25%" }}
                            {...register("pre_event_rehearsal_min")}
                        />
                        <NumberInput
                            sx={{ width: "25%" }}
                            source="number_of_hosts"
                            {...register("number_of_hosts")}
                        />
                    </Stack>
                    <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                        <BooleanInput source="pre_event_analytics" />
                        <BooleanInput source="event_analytics" />
                        <BooleanInput source="post_event_analytics" />
                    </Stack>
                    <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                        <BooleanInput source="live_chat" />
                        <BooleanInput source="screenshare" />
                        <BooleanInput source="event_recording" />
                        <BooleanInput source="knocks" />
                    </Stack>
                    <SelectInput
                        source="audience_size"
                        required={false}
                        defaultValue={500}
                        autoWidth={true}
                        choices={[
                            { id: "500", name: "< 500" },
                            { id: "1000", name: "< 1000" },
                            { id: "2000", name: "< 2000" },
                            { id: "3000", name: "< 3000" },
                            { id: "5000", name: "< 5000" },
                            { id: "10000", name: "< 10000" },
                            { id: "25000", name: "< 25000" },
                            { id: "50000", name: "< 50000" },
                            { id: "", name: "On request" },
                        ]}
                        {...register("audience_size")}
                    />
                </Stack>
                <Typography variant="h6" gutterBottom>
                    Prices
                </Typography>
                <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <NumberInput
                        source="attendee_overage_usd"
                        step={0.01}
                        resettable={true}
                        sx={{ width: "25%" }}
                        {...register("attendee_overage_usd")}
                    />
                    <NumberInput
                        source="duration_overage_usd"
                        step={0.01}
                        resettable={true}
                        sx={{ width: "25%" }}
                        {...register("duration_overage_usd")}
                    />
                </Stack>
                <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
                    <Stack direction="column" sx={{ width: "50%" }} spacing={2}>
                        <NumberInput
                            label="Price per month < 500 users"
                            step="0.01"
                            source="price_per_month_500"
                            {...register("price_per_month_500")}
                        />
                        <NumberInput
                            label="Price per month < 1000 users"
                            step="0.01"
                            source="price_per_month_1000"
                            {...register("price_per_month_1000")}
                        />
                        <NumberInput
                            label="Price per month < 2000 users"
                            step="0.01"
                            source="price_per_month_2000"
                            {...register("price_per_month_2000")}
                        />
                        <NumberInput
                            label="Price per month < 3000 users"
                            step="0.01"
                            source="price_per_month_3000"
                            {...register("price_per_month_3000")}
                        />
                        <NumberInput
                            label="Price per month < 5000 users"
                            step="0.01"
                            source="price_per_month_5000"
                            {...register("price_per_month_500")}
                        />
                        <NumberInput
                            label="Price per month < 10000 users"
                            step="0.01"
                            source="price_per_month_10000"
                            {...register("price_per_month_10000")}
                        />
                        <NumberInput
                            label="Price per month < 25000 users"
                            step="0.01"
                            source="price_per_month_25000"
                            {...register("price_per_month_25000")}
                        />
                        <NumberInput
                            label="Price per month < 50000 users"
                            step="0.01"
                            source="price_per_month_50000"
                            {...register("price_per_month_50000")}
                        />
                    </Stack>
                    <Stack direction="column" sx={{ width: "50%" }} spacing={2}>
                        <NumberInput
                            label="Price per year < 500 users"
                            step="0.01"
                            source="price_per_year_500"
                            {...register("price_per_year_500e")}
                        />
                        <NumberInput
                            label="Price per year < 1000 users"
                            step="0.01"
                            source="price_per_year_1000"
                            {...register("price_per_year_1000")}
                        />
                        <NumberInput
                            label="Price per year < 2000 users"
                            step="0.01"
                            source="price_per_year_2000"
                            {...register("price_per_year_2000")}
                        />
                        <NumberInput
                            label="Price per year < 3000 users"
                            step="0.01"
                            source="price_per_year_30000"
                            {...register("price_per_year_30000")}
                        />
                        <NumberInput
                            label="Price per year < 5000 users"
                            step="0.01"
                            source="price_per_year_5000"
                            {...register("price_per_year_5000")}
                        />
                        <NumberInput
                            label="Price per year < 10000 users"
                            step="0.01"
                            source="price_per_year_10000"
                            {...register("price_per_year_10000")}
                        />
                        <NumberInput
                            label="Price per year < 25000 users"
                            step="0.01"
                            source="price_per_year_25000"
                            {...register("price_per_year_25000")}
                        />
                        <NumberInput
                            label="Price per year < 50000 users"
                            step="0.01"
                            source="price_per_year_50000"
                            {...register("Price per year < 50000 users")}
                        />
                    </Stack>
                </Stack>
            </SimpleForm>
        </Edit>
    );
}