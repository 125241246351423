// in src/users.js
import { Chip } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import {
    BooleanField,
    Datagrid,
    List,
    TextField,
    TextInput,
    useRefresh,
} from "react-admin";

export const SubscriptionTypesList = (props) => {
    const refresh = useRefresh();
    const refreshInterval = 60 * 1000; // 30 seconds

    useEffect(() => {
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(refresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refresh, refreshInterval]);

    const QuickFilter = ({ label }) => {
        return <Chip sx={{ marginBottom: 1 }} label={label} />;
    };

    const postFilters = [
        <TextInput label="Search" source="q" alwaysOn />,
        <QuickFilter label="Active" source="active" defaultValue={true} />,
    ];

    return (
        <List
            {...props}
            pagination={false}
            sort={{ field: "title", order: "ASC" }}
            filters={postFilters}
        >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
                <TextField source="title" />
                <TextField source="id" />
                <TextField source="type" />
                <TextField source="version" />
                <BooleanField source="active" />
            </Datagrid>
        </List>
    );
};
