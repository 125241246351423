import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import {
    ArrayInput,
    AutocompleteInput,
    Button,
    Create,
    DateInput, ListButton,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Toolbar,
    TopToolbar
} from "react-admin";
import { useForm } from 'react-hook-form';


const CreateActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        {/*<ShowButton basePath={basePath} record={data} />*/}
        {/* Add your custom actions */}
        {/*<SuspendInstanceButton record={data} color="primary" >Suspend</SuspendInstanceButton>*/}
    </TopToolbar>
);

const optionRendererUser = (choice) => `${choice.username} <${choice.email}>`;
const optionRendererSubscription = (choice) =>
    `${choice.title}, v${choice.version}`;

 const validateUserCreation = (values) => {
        const errors = {};
        if (values.user_id.length > 1) {
            if (values.valid_from >= values.valid_to){
                errors.valid_to = "Date-from must be smaller then date-to";
            }
       
        }
        return errors;
    };
    

export const UserSubscriptionCreate = (props) => {

    const { watch, register } = useForm();
    const [changeInput, setChangeInput]= useState(false)

    useEffect(() => {
        watch((value, { name, type }) => {
            if (type === 'change') {
                setChangeInput(true)
            }}
        )
    }, [watch, changeInput]);

    const UserEditToolbar = (props) => {

        return(
            <Toolbar {...props}>
                <SaveButton  alwaysEnable={changeInput}/>
             </Toolbar>
        )
    }

    var currentDate = new Date();
    
    
    return (
        <Create sx={{ width: "100%" }} actions={<CreateActions />} {...props}>
            <SimpleForm toolbar={<UserEditToolbar />} validate={validateUserCreation}>
                <Typography variant="h6" gutterBottom>
                    User
                </Typography>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <ReferenceInput
                        label="User"
                        source="user_id"
                        name="Ref"
                        reference="users"
                        sort={{ field: "username", order: "ASC" }}
                        {...register("user_id")} 
                    >
                        <AutocompleteInput
                            optionText={optionRendererUser}
                            isRequired={true}
                            name="user_id"
                            validate={[required()]}
                       
                        />
                    </ReferenceInput>
                </Stack>
                <Typography variant="h6" gutterBottom>
                    Subscription type
                </Typography>
                <Stack direction="column" sx={{ width: "100%" }}>
                    <ReferenceInput
                        label="Subscription"
                        source="subscription_id"
                        reference="subscription_types"
                        sort={{ field: "title", order: "ASC" }}

                        filter={{
                            field: "active",
                            operator: "==",
                            value: true,
                        }}
                    >
                        <SelectInput
                            optionText={optionRendererSubscription}
                            isRequired={true}
                            validate={[required()]}
                            defaultValue="TOoYAbdN3wj2mjI3emI0"
                            onChange={(e)=> console.log('TESTTEST',e.target.value)}
                        />
                    </ReferenceInput>
                </Stack>
                <Typography variant="h6" gutterBottom>
                    Date valid
                </Typography>
                <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                    <DateInput
                        label={"From"}
                        source="valid_from"
                        sx={{ minWidth: 250, width: "50%" }}
                        isRequired={true}
                        validate={[required()]}
                        defaultValue={currentDate.toISOString().substring(0, 10)}
                        {...register("valid_from")} 
                    />
                    <DateInput
                        label={"To"}
                        source="valid_to"
                        sx={{ minWidth: 250, width: "50%" }}
                        isRequired={true}
                        validate={[required()]}
                       defaultValue={currentDate.toISOString().substring(0, 10)}
                        {...register("valid_to")} 
                    />
                </Stack>
                <Typography variant="h6" gutterBottom>
                    Add hosts
                </Typography>

                {/* TODO:  */}
                <Box sx={{ width: "50%" }}>
                    <ArrayInput
                        label=""
                        className="c-form-control"
                        source="hosts"
                        defaultValue={[]}
                    >
                        <SimpleFormIterator
                            addButton={
                                <Button>
                                    <AddCircleIcon />
                                </Button>
                            }
                            removeButton={
                                <Button>
                                    <RemoveCircleIcon />
                                </Button>
                            }
                            disableReordering
                            disableRowNumber
                            className="c-form-iterator"
                        >
                            <TextInput source="mail" sx={{ width: "100%" }} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </Box>
            </SimpleForm>
        </Create>
    );
};
