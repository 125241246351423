import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import {
    CheckboxGroupInput,
    RadioButtonGroupInput,
    BooleanInput,
    FileField
} from 'react-admin';
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import StopCircleRoundedIcon from "@mui/icons-material/StopCircleRounded";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import BanIcon from "@mui/icons-material/ThumbDownRounded";
import LiftBanIcon from "@mui/icons-material/ThumbUpRounded";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import {
    ArrayField,
    BooleanField,
    Button,
    DateField,
    EmailField,
    ImageField,
    ListButton,
    NumberField,
    RichTextField,
    FunctionField,
    Show,
    SingleFieldList,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    SimpleForm,
    useRecordContext,
    useRefresh,
    SaveButton,
    Toolbar,
} from "react-admin";
import firebaseClient from "../../../utils/FirebaseClient";
import {
    convertMsToTime,
    formatDateTime,
    renderDuration,
    getTenantId,
    isB2B
} from "../../../utils/HelperFunctions";
import { string } from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CopyToClipboardButton from "../../common/CopyToClippboardButton";
import VideoWrapper from "./../../common/VideoWrapper";
import { Stack } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import TipsFilteredList from "../tips/FilteredList";
import TicketsFilteredList from "../tickets/FilteredList";
import FanoutsFilteredList from "../fanouts/FilteredList";
import CropperFile from '../../common/CropperFile';

const theme = createTheme({
    palette: {
        mode: "dark",
    },
    components: {
        MuiTableRow: {
            defaultProps: {
                hover: true,
            },
        },
    },
});

const MainTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return "[resource_name] #[record_id]";
    return <span>Show: "{record.title}" #{record.id}</span>;
};

function showLayoutName(name) {
    let retVal = '';

    switch (name) {
        case 'ad-participants':
            retVal = 'Ad+Participants';
            break;
        case 'speaker':
            retVal = 'Ad+Active Speaker';
            break;
        case 'crop':
            retVal = 'Zoom in';
            break;
        case 'no-crop':
            retVal = 'Zoom out';
            break;
        default:
            retVal = '';
            break;
    }

    return retVal;
}

const StreamLayoutField = (props) => {
    const record = useRecordContext(props);
    return record && record.callParams && record.callParams.layoutParams && record.callParams.layoutParams.defaultLayout ? <span>{showLayoutName(record.callParams.layoutParams.defaultLayout)}</span> : null;
}

const InstanceShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

const Recording = ({ eventId }) => {
    const record = useRecordContext();
    // console.log(record);
    const [doc, setDoc] = useState(null);
    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);

    // eslint-disable-next-line
    const generateChaptersLink = async () => {
        let chaptersLink = null;

        if (doc.id) {
            setProcessing(true)
            chaptersLink = await firebaseClient.generateVTTFile(doc.id).then((res) => res).catch((e) => console.error('Error:generateVTTFile', e));
            setProcessing(false);
        }

        if (chaptersLink) {
            doc.videoUrls[0].chaptersLink = chaptersLink;
        }

        onDocUpdate({ data: doc });
    }

    const onDocUpdate = async (doc) => {
        console.log("onDocUpdate", doc);
        // console.log("Got FS data: ", doc.data());
        let post = doc ? doc.data : null;
        if (post) {
            let videoUrls = null, captions = [];

            if ((post.recordingLink) || (post.recordingStatus && post.recordingStatus === 'ready')) {
                videoUrls = await firebaseClient.getVideoLinks(post.id, true, true);
                post.totalDuration = renderDuration(post);
                post.videoLink = videoUrls && videoUrls.length && videoUrls[0].url ? videoUrls[0].url : null;
                post.audioLink = videoUrls && videoUrls.length && videoUrls[0].audioLink ? videoUrls[0].audioLink : null;
                post.captionsLink = videoUrls && videoUrls.length && videoUrls[0].captionsLink ? videoUrls[0].captionsLink : null;
                post.chaptersLink = videoUrls && videoUrls.length && videoUrls[0].chaptersLink ? videoUrls[0].chaptersLink : null;
                captions = videoUrls && videoUrls.length && videoUrls[0].captionsLink ? [{ src: videoUrls[0].captionsLink, kind: 'captions', srclang: 'en', label: 'English', mode: 'showing' }] : [];

                if (post.chaptersLink) {
                    captions.push({ src: post.chaptersLink, kind: 'chapters', srclang: 'en' });
                }
            }

            post.videoUrls = videoUrls || null;
            if (post.videoUrls && post.videoUrls.forEach) {
                post.videoUrls.forEach((part, i) => {
                    post.videoUrls[i].captions = captions;
                })
            }

            console.log('post', post);
            setDoc(post || null);
        } else {
            setDoc(null);
        }


        setLoaded(true);
    };

    useEffect(() => {
        firebaseClient.readObject(getTenantId('posts'), record.id)
            .then(onDocUpdate)
            .catch((e) => {
                setError(e.message);
                console.error(e);
            })
            ;
        return () => {
        };
    }, [record.id]);

    return record && record.id && record.recordingStatus && ((record.recordingStatus === 'processing') || (record.recordingStatus === 'error')) ?
        <div>{
            'The recording is still being processed'
        }</div>
        : record && record.id && doc && doc.videoUrls ? (
            <TableContainer
                sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                }}
                component={Paper}
            >
                <Table size="small">
                    <TableRow>
                        <TableCell component="th" scope="row" sx={{ width: 300 }}>
                            <Typography component="span" variant="body2">
                                Available from
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <span>
                                {doc.archiveStartDate !== undefined
                                    ? moment(formatDateTime(doc.archiveStartDate)).format(
                                        "MM/DD/YYYY HH:mm:ss"
                                    )
                                    : null}
                            </span>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Typography component="span" variant="body2">
                                Available to
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {doc.archiveStopDate !== undefined
                                ? moment(formatDateTime(doc.archiveStopDate)).format(
                                    "MM/DD/YYYY HH:mm:ss"
                                )
                                : null}
                        </TableCell>
                    </TableRow>
                    {!isB2B() ?
                        <TableRow>
                            <TableCell component="th" scope="row">
                                <Typography component="span" variant="body2">
                                    Price
                                </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {doc.archivePrice !== undefined
                                    ? doc.archivePrice
                                    : null}
                            </TableCell>
                        </TableRow>
                        : null
                    }
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Typography component="span" variant="body2">
                                Duration [hh:mm:ss]
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {doc.totalDuration}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Typography component="span" variant="body2">
                                Video
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <FileField source="videoLink" emptyText="none" title={`${doc.title} (Video link)`} download='test' target="_blank" record={doc} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Typography component="span" variant="body2">
                                Audio
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <FileField source="audioLink" emptyText="none" title={`${doc.title} (Audio link)`} download target="_blank" record={doc} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Typography component="span" variant="body2">
                                Captions
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <FileField source="captionsLink" emptyText="none" title={`${doc.title} (Captions link)`} download target="_blank" record={doc} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Typography component="span" variant="body2">
                                Chapters
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <FileField source="chaptersLink" emptyText="none" title={`${doc.title} (Chapters link)`} download target="_blank" record={doc} />
                            {/* {doc.recordingSubtitlesAvailable ?
                                <button className='generate-chapters-btn' onClick={() => generateChaptersLink()} disabled={processing}>{`Generate ${doc.chaptersLink ? 'new ' : ''}chapters link`}</button>
                                : null
                            } */}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <Typography component="span" variant="body2">
                                Summary
                            </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {doc.recordingSummary ?
                                <div>
                                    {doc.recordingSummary?.summary ||
                                        doc.recordingSummary?.general_overview ||
                                        doc.recordingSummary?.show_overview ||
                                        doc.recordingSummary?.show_summary ||
                                        doc.recordingSummary?.general_show_overview}
                                    <ul>
                                        {!doc.recordingSummary.main_topics ? null :
                                            doc.recordingSummary?.main_topics.map(
                                                (t) => {
                                                    return (typeof t === 'string') ?
                                                        (<li>t</li>) :
                                                        (<li><b>{t.name || t.topic || t.title}</b>{': ' + t.description}</li>);
                                                })
                                        }
                                    </ul>
                                </div>
                                : ('none')
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row" colSpan={2}>
                            <VideoWrapper item={doc} />
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        ) : (
            <div>{
                error ? error :
                    !loaded ? 'Loading...' :
                        'No recording for this event'
            }</div>
        );
};

const Stats = ({ eventId }) => {
    const record = useRecordContext();
    // console.log(record);
    const [doc, setDoc] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        console.log("onDocUpdate", doc);
        // console.log("Got FS data: ", doc.data());
        let post = doc ? doc.data : null;
        if (post) {
            if (post.stats && ((!post.stats.created_users_emails) || (post.stats.created_users_emails && !post.stats.created_users_emails.length))) {
                await firebaseClient.getAllSearchObject({ collection: `${getTenantId('posts')}/${post.id}/created_users_emails` })
                    .then((res) => {
                        post.stats.created_users_emails = res.data;
                    }).catch(e => {
                        setError(e.message);
                        console.error(e);
                    });
            }
            if (post.stats && !post.stats.total_clicks) {
                await firebaseClient.getAllSearchObject({ collection: `${getTenantId('posts')}/${post.id}/clicks` })
                    .then((res) => {
                        post.stats.total_clicks = res.data && res.data.length ? res.data.length : 0;
                    }).catch(e => {
                        setError(e.message);
                        console.error(e);
                    });
            }
            setDoc(post || null);
        } else {
            setDoc(null);
        }
        setLoaded(true);
    };

    useEffect(() => {
        firebaseClient.readObject(getTenantId('posts'), record.id)
            .then(onDocUpdate)
            .catch(e => {
                setError(e.message);
                console.error(e);
            })
            ;
        return () => {
        };
    }, [record.id]);

    return record && record.id && doc && doc.stats ? (
        <TableContainer
            sx={{
                marginTop: "20px",
                marginBottom: "20px",
            }}
            component={Paper}
        >
            <Table size="small">
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        <Typography component="span" variant="body2">
                            Started
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <span>
                            {doc.stats.started
                                ? moment(formatDateTime(doc.stats.started)).format(
                                    "MM/DD/YYYY HH:mm:ss"
                                )
                                : '-'}
                        </span>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <Typography component="span" variant="body2">
                            Stopped
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.stats.stopped
                            ? moment(formatDateTime(doc.stats.stopped)).format(
                                "MM/DD/YYYY HH:mm:ss"
                            )
                            : '-'}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <Typography component="span" variant="body2">
                            Duration [hh:mm:ss]
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {convertMsToTime(doc.stats.duration) || '-'}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <Typography component="span" variant="body2">
                            Total users
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.stats.total_users}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <Typography component="span" variant="body2">
                            Max users
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.stats.max_users}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <Typography component="span" variant="body2">
                            Total audience
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.stats.total_audience}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <Typography component="span" variant="body2">
                            Max audience
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.stats.max_audience}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <Typography component="span" variant="body2">
                            Created users
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.stats.created_users_emails && doc.stats.created_users_emails.length ?
                            doc.stats.created_users_emails.length
                            :
                            0
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        <Typography component="span" variant="body2">
                            Total clicks on ads
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.stats.total_clicks ?
                            doc.stats.total_clicks
                            :
                            0
                        }
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    ) : (
        <div>{
            error ? error :
                !loaded ? 'Loading...' :
                    'No stats for this event'
        }</div>
    );
};

const Bill = ({ eventId }) => {
    const record = useRecordContext();
    // console.log(record);
    const [doc, setDoc] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        console.log("onDocUpdate", doc);
        // console.log("Got FS data: ", doc.data());
        let post = doc ? doc.data : null;
        post.totalDuration = 0;
        setLoaded(true);
        if (post) {

            setDoc(post || null);
        } else {
            setDoc(null);
        }
    };

    useEffect(() => {
        firebaseClient.readObject(getTenantId('posts'), record.id)
            .then(onDocUpdate)
            .catch(e => {
                setError(e.message);
                console.error(e);
            });
        return () => {
        };
    }, [record.id]);

    return record && record.id && doc && doc.bill ? (
        <TableContainer
            sx={{
                marginTop: "20px",
                marginBottom: "20px",
            }}
            component={Paper}
        >
            <Table size="small">
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        <Typography component="span" variant="body2">
                            Total participants
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <span>{doc.bill.total_users}</span>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        <Typography component="span" variant="body2">
                            Duration
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {convertMsToTime(doc.bill.duration)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        <Typography component="span" variant="body2">
                            Duration 15min
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {convertMsToTime(doc.bill.duration_15min)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        <Typography component="span" variant="body2">
                            Duration overage
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.bill.duration_overage_amount
                            ? `${doc.bill.duration_overage_amount}`
                            : "-"}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        <Typography component="span" variant="body2">
                            Attendee overage
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.bill.attendee_overage_amount
                            ? `${doc.bill.attendee_overage_amount}`
                            : "-"}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        <Typography component="span" variant="body2">
                            Total overage
                        </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {doc.bill.total_overage ? `${doc.bill.total_overage}` : "-"}
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    ) : (
        <div>{
            error ? error :
                !loaded ? 'Loading...' :
                    'No bill for this event'
        }</div>
    );
};

const Streaming = () => {
    const record = useRecordContext();
    // console.log(record);
    const [doc, setDoc] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        console.log("onDocUpdate", doc);
        console.log("Got FS data: ", doc.data);
        let { data } = doc || null;
        setLoaded(true);
        if (data && data.length > 0) {
            let events = data.sort((a, b) => {
                return a.when > b.when ? 1 : -1;
            }) || null;
            // console.log("streaming_activity", events);
            setDoc(events || null);
        } else {
            setDoc(null);
        }
    };

    useEffect(() => {
        firebaseClient.getAllSearchObject({ collection: `/activities/${record.id}/log` })
            .then(onDocUpdate)
            .catch(e => {
                setError(e.message);
                console.error(e);
            });
        return () => {
        };
    }, [record.id]);

    return record && record.id && doc && doc.length ? (
        <Timeline>
            {doc.map((data) => {
                return (
                    <TimelineItem
                        sx={{
                            "& .MuiTimelineConnector-root": {
                                height: "10px",
                            },
                        }}
                    >
                        <TimelineOppositeContent sx={{ m: "auto 0" }}>
                            <Typography variant="body2" component="span">
                                <DateField source="when" record={data} showTime={false} locales="en-US" />
                            </Typography>
                            <Typography variant="body2">
                                <DateField source="when" record={data} showTime={true} showDate={false} locales="en-US" />
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="inherit">
                                {data.what === "start" ? (
                                    <PlayCircleFilledIcon fontSize="large" />
                                ) : data.what === "pause" ? (
                                    <PauseCircleFilledIcon fontSize="large" />
                                ) : data.what === "begin" ? (
                                    <CircleRoundedIcon
                                        fontSize="large"
                                        style={{ color: "#819b4f" }}
                                    />
                                ) : data.what === "end" ? (
                                    <CircleRoundedIcon
                                        fontSize="large"
                                        style={{ color: "#ed0e0e" }}
                                    />
                                ) : data.what === "ban" ? (
                                    <BanIcon
                                        fontSize="large"
                                        style={{ color: "#ffffff" }}
                                    />
                                ) : data.what === "lift-ban" ? (
                                    <LiftBanIcon
                                        fontSize="large"
                                        style={{ color: "#ffffff" }}
                                    />
                                ) : data.what === "join" ? (
                                    <LoginRoundedIcon
                                        fontSize="large"
                                        style={{ color: "#ffffff" }}
                                    />
                                ) : data.what === "leave" ? (
                                    <LogoutRoundedIcon
                                        fontSize="large"
                                        style={{ color: "#ffffff" }}
                                    />
                                ) : (
                                    <StopCircleRoundedIcon fontSize="large" />
                                )}
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent
                            sx={{
                                py: "22px",
                                px: 2,
                                "& .MuiTypography-body1": {
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "start",
                                },
                            }}
                        >
                            <Typography variant="body2" component="span">
                                {data.what.charAt(0).toUpperCase() +
                                    data.what.slice(1)}
                            </Typography>
                            <Typography variant="body2">
                                {typeof data.who === "object"
                                    ? data.who.name
                                    : data.who}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </Timeline>
    ) : (
        <div>{
            error ? error :
                !loaded ? 'Loading...' :
                    'No streaming data for this event'
        }</div>
    );
};

const Participants = ({ eventId }) => {
    const record = useRecordContext();
    // console.log(record);
    const [doc, setDoc] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        console.log("onDocUpdate", doc);
        // console.log("Got FS data: ", doc.data());
        let post = doc ? doc.data : null;
        post.totalDuration = 0;
        if (post) {
            let { stats } = post;

            if (stats && ((!stats.users) || (stats.users && !Object.values(stats.users).length))) {
                await firebaseClient.getAllSearchObject({ collection: `${getTenantId('posts')}/${post.id}/users` })
                    .then((res) => {
                        stats.users = res.data;
                    }).catch(e => {
                        setError(e.message);
                        console.error(e);
                    });
            }

            if (stats) {
                let users = Object.values(stats.users || {}).sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                });
                stats.users = users;
                console.log("Got user data: ", stats, stats.users);
            }
            setDoc(stats || null);
        } else {
            setDoc(null);
        }
        setLoaded(true);
    };

    useEffect(() => {
        firebaseClient.readObject(getTenantId('posts'), record.id)
            .then(onDocUpdate)
            .catch(e => {
                setError(e.message);
                console.error(e);
            });
        return () => {
        };
    }, [record.id]);

    const columns = [
        {
            field: "name",
            headerName: "Name",
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
            sort: "desc",
        },
        {
            field: "uid",
            headerName: "User ID",
            minWidth: 300,
            maxWidth: 400,
            flex: 1,
            renderCell: (params) => <div className="data-cell__copy-to-clipboard"><span>{params.id}</span><CopyToClipboardButton string source={params.id} className="launch-link" /></div>
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 300,
            maxWidth: 400,
            flex: 1,
        },
        {
            field: "userRole",
            headerName: "Role",
            minWidth: 150,
            maxWidth: 300,
            flex: 1,
            valueOptions: ["Audience", "Guest speaker", "Presenter"],
            option: { value: string, label: string },
            type: "singleSelect",
        },
        {
            field: "wasAnonymous",
            headerName: "Anonymous",
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
            renderCell: (params) => ((params.value) || (params && params.row && params.row.isAnonymous)) ? <CheckIcon /> : <CloseIcon />
        },
        {
            headerName: "Created",
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
            renderCell: (params) => params && params.row && params.row.email && record && record.stats && record.stats.created_users_emails && record.stats.created_users_emails.length && record.stats.created_users_emails.indexOf(params.row.email) !== -1 ? <CheckIcon /> : <CloseIcon />
        },
        {
            field: "total_clicks",
            headerName: "Clicks",
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
            renderCell: (params) => params && params.row && params.row.total_clicks ? params.row.total_clicks : 0
        },
        {
            field: "registeredUser",
            headerName: "Registered",
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
        },
        {
            field: "enterDateNumber",
            headerName: "First join",
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
            options: {
                sort: true,
                customBodyRender: (value) =>
                    value ? moment(new Date(value)).format(SPACED_DATE_FORMAT) : '-',
            },
        },
        {
            field: "leaveDateNumber",
            headerName: "Last leave",
            minWidth: 200,
            maxWidth: 300,
            flex: 1,
            options: {
                sort: true,
                customBodyRender: (value) =>
                    value ? moment(new Date(value)).format(SPACED_DATE_FORMAT) : '-',
            },
            type: "date",
        },
        {
            field: "totalSum",
            headerName: "Total",
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
        },
    ];

    const SPACED_DATE_FORMAT = "MMM DD YYYY";

    const gridData = doc && doc.users && doc.users.length ? doc.users.map((row) => {
        const dateTimeFormatShape = "MM/DD/YYYY HH:mm:ss";
        const dateFormatShape = "MM/DD/YYYY";
        const updatedRow = {
            ...row,
            id: `${row.name}ID`,
            registeredUser: row.registered ? moment(row.registered).format(dateFormatShape) : null,
            enterDateNumber: row.firstJoin ? moment(row.firstJoin).format(dateTimeFormatShape) : null,
            leaveDateNumber: row.lastLeave ? moment(row.lastLeave).format(dateTimeFormatShape) : null,
            userRole:
                row.role[0].toUpperCase() + row.role.slice(1).replace("_", " "),
            totalSum: row.total ? new Date(row.total * 1000)
                .toISOString()
                .substring(11, 19) : null,
        };
        return updatedRow;
    }) : null;

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    }

    return record && record.id && doc ? (
        <div style={{ display: "flex", height: "100%", minHeight: "100%" }}>
            <div style={{ flexGrow: 1 }}>
                {!doc.users || !doc.users.length ? (
                    "No user data for this event"
                ) : (
                    <React.Fragment>
                        <DataGrid
                            disableColumnMenu={true}
                            sx={{
                                width: "100%",
                                height: "auto",
                                border: 0,
                                "& .MuiDataGrid-columnSeparator--sideRight": {
                                    display: "none",
                                },
                                "& .MuiDataGrid-columnHeaders + div": {
                                    height: "auto !important",
                                    minHeight: "100px",
                                },
                                "& .MuiDataGrid-virtualScroller": {
                                    height: "auto !important",
                                },
                            }}
                            getRowId={(row) => row.uid}
                            rows={gridData}
                            columns={columns}
                            pageSize={100}
                            withBorder={false}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: "name", sort: "ASC" }],
                                },
                            }}
                        />
                    </React.Fragment>
                )}
            </div>
        </div>
    ) : (
        <div>{
            error ? error :
                !loaded ? 'Loading...' :
                    'No participants for this event'
        }</div>
    );
};

const GeneralInfoTable = ({ eventId }) => {
    const record = useRecordContext();
    const refresh = useRefresh();

    const [doc, setDoc] = useState(null);
    // eslint-disable-next-line
    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line
    const [error, setError] = useState(false);

    const onDocUpdate = async (doc) => {
        console.log("onDocUpdate post", doc);
        let post = doc ? doc.data : null;

        if (post) {
            let banners = post.callParams && post.callParams.layoutParams && post.callParams.layoutParams.banners && post.callParams.layoutParams.banners.length ? post.callParams.layoutParams.banners : [], banner = null;

            if (banners && banners.length) {
                banner = banners.filter(item => item.default)[0];

                if (!banner) {
                    banner = banners[0];
                }

                post.banner = banner
                post.bannerImg = banner.img;
            }
            setDoc(post || null);
        } else {
            setDoc(null)
        }
        setLoaded(true);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        firebaseClient.readObject(getTenantId('calendar'), record.id)
            .then(onDocUpdate)
            .catch((e) => {
                setError(e.message);
                console.error(e);
            })
            ;
        return () => {
        };
    }, [record.id]);

    const HiddenInToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenHiddenIn(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const BannersToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenBanners(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const VisibilityToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenVisibility(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const VersionToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenVersion(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const StreamLayoutToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenStreamLayout(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const VideoLabelsToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenVideoLabels(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const StatusToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenStatus(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const LiveToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenLive(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const ArchiveEnabledToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenArchiveEnabled(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const AcceptTipsToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenAcceptTips(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const TypeToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenType(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const PopularToolbar = props => (
        <Toolbar {...props} sx={{
            "& ": {
                background: "#383838",
            },
        }}>
            <Stack direction="horizontal" sx={{ "& ": { justifyContent: 'space-between', width: "100%" } }}>
                <SaveButton alwaysEnable label="Save" />
                <Button onClick={() => setOpenPopular(false)}>Close</Button>
            </Stack>

        </Toolbar>
    );

    const [openHiddenIn, setOpenHiddenIn] = React.useState(false);
    const [openBanners, setOpenBanners] = React.useState(false);
    const [openVisibility, setOpenVisibility] = React.useState(false);
    const [openVersion, setOpenVersion] = React.useState(false);
    const [openStreamLayout, setOpenStreamLayout] = React.useState(false);
    const [openVideoLabels, setOpenVideoLabels] = React.useState(false);
    const [openStatus, setOpenStatus] = React.useState(false);
    const [openLive, setOpenLive] = React.useState(false);
    const [openArchiveEnabled, setOpenArchiveEnabled] = React.useState(false);
    const [openAcceptTips, setOpenAcceptTips] = React.useState(false);
    const [openType, setOpenType] = React.useState(false);
    const [openPopular, setOpenPopular] = React.useState(false);

    const handleSubmitHiddenIn = async (data) => {
        console.log("About to save hiddenIn", data);
        await firebaseClient.update(getTenantId('posts'), { hiddenIn: data.hiddenIn }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { hiddenIn: data.hiddenIn }, data.id);
        refresh();
        setOpenHiddenIn(false);
    };
    const handleSubmitBanners = async (data) => {
        console.log("About to save banners", data.bannerLink, doc.bannerImg);

        let oldBanners = [], oldDefaultBanner = {}, newBanner = {};

        if (data.callParams && data.callParams.layoutParams && data.callParams.layoutParams.banners && data.callParams.layoutParams.banners.length) {
            oldBanners = data.callParams.layoutParams.banners;
        }

        if (oldBanners && oldBanners.length) {
            oldDefaultBanner = oldBanners.filter(item => item.default)[0];

            if (!oldDefaultBanner) {
                oldDefaultBanner = oldBanners[0];
            }
        }

        newBanner = Object.assign(oldDefaultBanner, {
            img: doc.bannerImg,
            link: data.bannerLink
        });

        data.callParams.layoutParams.banners = [newBanner];
        doc.banner = newBanner;

        await firebaseClient.update(getTenantId('posts'), { callParams: data.callParams }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { callParams: data.callParams }, data.id);

        if (data.status && data.status !== 'ended' && data.status !== 'cancelled') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}/callParams/layoutParams`, { banners: data.callParams.layoutParams.banners });
        }

        refresh();
        setDoc(doc);
        setOpenBanners(false);
    };
    const handleSubmitVisibility = async (data) => {
        console.log("About to save visibility", data);
        await firebaseClient.update(getTenantId('posts'), { visibility: data.visibility }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { visibility: data.visibility }, data.id);
        refresh();
        setOpenVisibility(false);
    };
    const handleSubmitVersion = async (data) => {
        console.log("About to save version", data);
        await firebaseClient.update(getTenantId('posts'), { version: data.version }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { version: data.version }, data.id);

        if (data.status && data.status !== 'ended' && data.status !== 'cancelled') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}`, { version: data.version });
        }

        refresh();
        setOpenVersion(false);
    };
    const handleSubmitStreamLayout = async (data) => {
        console.log("About to save stream layout", data);
        await firebaseClient.update(getTenantId('posts'), { callParams: data.callParams }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { callParams: data.callParams }, data.id);

        if (data.status && data.status !== 'ended' && data.status !== 'cancelled') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}/callParams/layoutParams`, { layout: data.callParams.layoutParams.defaultLayout });
        }

        refresh();
        setOpenStreamLayout(false);
    };
    const handleSubmitVideoLabels = async (data) => {
        console.log("About to save participant name labels", data);
        await firebaseClient.update(getTenantId('posts'), { callParams: data.callParams }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { callParams: data.callParams }, data.id);

        if (data.status && data.status !== 'ended' && data.status !== 'cancelled') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}/callParams/layoutParams`, { labels: data.callParams.layoutParams.labels });
        }

        refresh();
        setOpenVideoLabels(false);
    };
    const handleSubmitLive = async (data) => {
        console.log("About to save live", data);
        await firebaseClient.update(getTenantId('posts'), { live: data.live }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { live: data.live }, data.id);
        refresh();
        setOpenLive(false);
    };
    const handleSubmitArchiveEnabled = async (data) => {
        console.log("About to save archiveEnabled", data);
        await firebaseClient.update(getTenantId('posts'), { archiveEnabled: data.archiveEnabled }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { archiveEnabled: data.archiveEnabled }, data.id);
        refresh();
        setOpenArchiveEnabled(false);
    };
    const handleSubmitAcceptTips = async (data) => {
        console.log("About to save acceptTips", data);
        await firebaseClient.update(getTenantId('posts'), { acceptTips: data.acceptTips }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { acceptTips: data.acceptTips }, data.id);
        refresh();
        setOpenAcceptTips(false);
    };
    const handleSubmitStatus = async (data) => {
        console.log("About to save status", data);
        await firebaseClient.update(getTenantId('posts'), { status: data.status }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { status: data.status }, data.id);
        refresh();
        setOpenStatus(false);
    };

    const handleSubmitPopular = async (data) => {
        console.log("About to save featured", data);
        await firebaseClient.update(getTenantId('posts'), { featured: data.featured }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { featured: data.featured }, data.id);
        refresh();
        setOpenPopular(false);
    };

    const handleSubmitType = async (data) => {
        console.log("About to save type", data);
        await firebaseClient.update(getTenantId('posts'), { type: data.type }, data.id);
        await firebaseClient.update(getTenantId('calendar'), { type: data.type }, data.id);

        if (data && data.type && data.type === 'test-event') {
            await firebaseClient.crudRT('update', `protected/event/${data.id}`, { type: data.type });
        } else {
            await firebaseClient.crudRT('update', `protected/event/${data.id}`, { type: data.type });
        }

        refresh();
        setOpenType(false);
    };

    const handleAddImage = (image) => {
        console.log('handleAddImage', image)
        doc.bannerImg = image;
        setDoc(doc);
    }

    return (
        <TableContainer
            sx={{
                marginTop: "20px",
                marginBottom: "20px",
            }}
            component={Paper}
        >
            <Dialog open={openHiddenIn} onClose={() => setOpenHiddenIn(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Hide event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<HiddenInToolbar />}
                        onSubmit={handleSubmitHiddenIn}
                    >
                        <CheckboxGroupInput
                            source="hiddenIn"
                            choices={[
                                { id: 'upcoming', name: 'Upcoming' },
                                { id: 'rotator', name: 'Rotator' },
                                { id: 'vault', name: 'Vault' },
                            ]}
                            row={false}
                            initialValue={record.hiddenIn}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openBanners} onClose={() => setOpenBanners(false)}  >
                {/* <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Banner:
                </DialogTitle> */}
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    }
                }}>
                    <div style={{ padding: '16px 16px 0 16px' }}>
                        <p>Banner image</p>
                        <CropperFile
                            file={doc && doc.banner && doc.banner.img ? doc.banner.img : null}
                            uid={record && record.uid ? record.uid : null}
                            handleAddImage={(image) => handleAddImage(image)}
                        />
                    </div>
                    <SimpleForm
                        toolbar={<BannersToolbar />}
                        onSubmit={handleSubmitBanners}
                        sx={{
                            "& ": {
                                paddingTop: '0'
                            }
                        }}
                    >
                        <p>Banner link</p>
                        <TextInput
                            source='bannerLink'
                            label=''
                            defaultValue={doc && doc.banner && doc.banner.link ? doc.banner.link : ''}
                            fullWidth
                            resettable
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openVisibility} onClose={() => setOpenVisibility(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Visibility event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<VisibilityToolbar />}
                        onSubmit={handleSubmitVisibility}
                    >
                        <RadioButtonGroupInput
                            source="visibility"
                            choices={[
                                { id: 'public', name: 'Public' },
                                { id: 'private', name: 'Private' }
                            ]}
                            row={false}
                            initialValue={record.visibility}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openVersion} onClose={() => setOpenVersion(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Version event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<VersionToolbar />}
                        onSubmit={handleSubmitVersion}
                    >
                        <RadioButtonGroupInput
                            source="version"
                            choices={[
                                { id: '3.0.0', name: '3.0.0' },
                                { id: '4.0.0', name: '4.0.0' },
                                { id: '4.1.0', name: '4.1.0' },
                            ]}
                            row={false}
                            initialValue={record.version}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openStreamLayout} onClose={() => setOpenStreamLayout(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Stream layout in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<StreamLayoutToolbar />}
                        onSubmit={handleSubmitStreamLayout}
                    >
                        <RadioButtonGroupInput
                            source="callParams.layoutParams.defaultLayout"
                            choices={[
                                { id: 'crop', name: 'Zoom in' },
                                { id: 'no-crop', name: 'Zoom out' },
                                { id: 'speaker', name: 'Ad+Active Speaker' },
                                { id: 'ad-participants', name: 'Ad+Participants' }
                            ]}
                            row={false}
                            initialValue={record.callParams && record.callParams.layoutParams && record.callParams.layoutParams.defaultLayout ? record.callParams.layoutParams.defaultLayout : 'no-crop'}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openVideoLabels} onClose={() => setOpenVideoLabels(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Participant name labels in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<VideoLabelsToolbar />}
                        onSubmit={handleSubmitVideoLabels}
                    >
                        <RadioButtonGroupInput
                            source="callParams.layoutParams.labels"
                            choices={[
                                { id: 'default', name: 'Default' },
                                { id: 'outlined', name: 'Outlined' }
                            ]}
                            row={false}
                            initialValue={record.callParams && record.callParams.layoutParams && record.callParams.layoutParams.labels ? record.callParams.layoutParams.labels : 'default'}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openStatus} onClose={() => setOpenStatus(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Status  event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<StatusToolbar />}
                        onSubmit={handleSubmitStatus}
                    >
                        <RadioButtonGroupInput
                            source="status"
                            choices={[
                                { id: 'scheduled', name: 'Scheduled' },
                                { id: 'started', name: 'Started' },
                                { id: 'ended', name: 'Ended' }
                            ]}
                            row={false}
                            initialValue={record.status}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openLive} onClose={() => setOpenLive(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Live  event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<LiveToolbar />}
                        onSubmit={handleSubmitLive}
                    >
                        <BooleanInput
                            source="live"
                            label="Live"
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openArchiveEnabled} onClose={() => setOpenArchiveEnabled(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Archive enabled  event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<ArchiveEnabledToolbar />}
                        onSubmit={handleSubmitArchiveEnabled}
                    >
                        <BooleanInput
                            source="archiveEnabled"
                            label="Archive Enabled"
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openAcceptTips} onClose={() => setOpenAcceptTips(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Accept tips  event in:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<AcceptTipsToolbar />}
                        onSubmit={handleSubmitAcceptTips}
                    >
                        <BooleanInput
                            source="acceptTips"
                            label="Accept Tips"
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openType} onClose={() => setOpenType(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    The show type is:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<TypeToolbar />}
                        onSubmit={handleSubmitType}
                    >
                        <RadioButtonGroupInput
                            source="type"
                            choices={[
                                { id: 'event', name: 'event' },
                                { id: 'test-event', name: 'test-event' }
                            ]}
                            row={false}
                            initialValue={record.type}
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Dialog open={openPopular} onClose={() => setOpenPopular(false)}  >
                <DialogTitle sx={{ textAlign: "center", paddingTop: '30px', minWidth: '360px' }}>
                    Popular on the platform:
                </DialogTitle>
                <DialogContent sx={{
                    "& ": {
                        paddingTop: '0',
                        paddingBottom: '30px',
                    },
                    "& > * > *:first-child ": {
                        paddingLeft: "110px",
                    },
                    "& .MuiFormLabel-root": {
                        display: 'none',
                    },
                    "& .MuiFormHelperText-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        paddingBottom: '20px',
                    }
                }}>
                    <SimpleForm
                        toolbar={<PopularToolbar />}
                        onSubmit={handleSubmitPopular}
                    >
                        <BooleanInput
                            source="featured"
                            label="Popular"
                            sx={{
                                "& .MuiCheckbox-colorPrimary": {
                                    color: "#fff"
                                }
                            }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
            <Table size="small">
                <TableRow>
                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                        Id
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField label="id" source="id" />{" "}
                        <CopyToClipboardButton source="id" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Event name
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="title" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Audience link
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {" "}
                        <FunctionField
                            label="Audience link"
                            render={(record) => (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={record.sharedLink}
                                >
                                    {record.sharedLink}
                                </a>
                            )}
                        />{" "}
                        <CopyToClipboardButton source="sharedLink" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Guest link
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {" "}
                        <FunctionField
                            label="Guest link"
                            render={(record) => (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={record.guestInvitationLink}
                                >
                                    {record.guestInvitationLink}
                                </a>
                            )}
                        />{" "}
                        <CopyToClipboardButton source="guestInvitationLink" />
                    </TableCell>
                </TableRow>
                {/*<TableRow>*/}
                {/*    <TableCell component="th" scope="row">*/}
                {/*        Event subtitle*/}
                {/*    </TableCell>*/}
                {/*    <TableCell component="th" scope="row">*/}
                {/*        <RichTextField label="" source="eventSubtitle" />*/}
                {/*    </TableCell>*/}
                {/*</TableRow>*/}
                <TableRow>
                    <TableCell component="th" scope="row">
                        Event description
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <RichTextField label="" source="descriptionText" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Created
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <DateField
                            label="Created"
                            source="date"
                            showTime={true}
                            locales="en-US"
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Starts
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <DateField
                            label="Starts"
                            source="startDate"
                            showTime={true}
                            locales="en-US"
                        />
                    </TableCell>
                </TableRow>
                {!isB2B() ?
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Price
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <NumberField source="price" options={{ style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }} />
                        </TableCell>
                    </TableRow>
                    : null
                }
                {!isB2B() ?
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Archive price
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <NumberField source="archivePrice" options={{ style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }} />
                        </TableCell>
                    </TableRow>
                    : null
                }
                {!isB2B() ?
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Tips value
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <NumberField source="tipsValue" options={{ style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }} />
                        </TableCell>
                    </TableRow>
                    : null
                }
                <TableRow>
                    <TableCell component="th" scope="row">
                        Logo
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <ImageField
                            source="logo"
                            title="logo"
                            sx={{
                                "& .RaImageField-image": {
                                    width: 100,
                                },
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Email
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <EmailField source="email" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Status
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="status" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenStatus(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Live
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="live" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenLive(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Popular on the platform
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="featured" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenPopular(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Hidden In
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {((!record.hiddenIn) || (record.hiddenIn && !record.hiddenIn.length)) ?
                            <span>not hidden</span> :
                            record.hiddenIn.map((item, index) => <span>{index ? ", " : ''}{item}</span>)}
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenHiddenIn(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Archive enabled
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="archiveEnabled" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenArchiveEnabled(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Accept tips
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="acceptTips" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenAcceptTips(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        User ID
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <div className="data-cell__copy-to-clipboard">
                            <FunctionField
                                label="User ID"
                                render={(record) => (
                                    <a
                                        href={`/#/users/${record.uid}/show/`}
                                    >
                                        {record.uid}
                                    </a>
                                )}
                            />
                            <CopyToClipboardButton source="uid" />
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Visibility
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="visibility" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenVisibility(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Version
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="version" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenVersion(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Stream Layout
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <StreamLayoutField source="callParams.layoutParams.defaultLayout" />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenStreamLayout(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Banner
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{
                            '&': {
                                display: 'flex'
                            }
                        }}
                    >
                        {(doc && doc.banner && doc.banner.img) ?
                            <div>
                                {doc.banner.link && doc.banner.img ?
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={doc.banner.link}
                                    >
                                        <ImageField
                                            source='img'
                                            record={doc.banner}
                                            sx={{
                                                '& .RaImageField-image': {
                                                    width: 300,
                                                    height: 'auto'
                                                }
                                            }}
                                        />
                                    </a>
                                    : doc.banner.img ?
                                        <ImageField
                                            source='img'
                                            record={doc.banner}
                                            sx={{
                                                '& .RaImageField-image': {
                                                    width: 300,
                                                    height: 'auto'
                                                }
                                            }}
                                        />
                                        : null
                                }
                            </div>
                            :
                            <span>none</span>
                        }
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenBanners(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Participant Name Labels
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField
                            source="callParams.layoutParams.labels"
                            sx={{ "& ": { textTransform: "capitalize" } }}
                        />
                        <button
                            className="copy-to-clipboard-btn"
                            onClick={() => setOpenVideoLabels(true)}
                        >
                            <EditIcon fontSize="inherit" />
                        </button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Hosts
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <ArrayField source="cohosts">
                            <Stack spacing={0}>
                                <EmailField source="email" />
                                <SingleFieldList
                                    sx={{
                                        "& ": {
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "start",
                                            flexWrap: "wrap",
                                            marginTop: "0",
                                            marginBottom: "0",
                                        },
                                        "& .RaSingleFieldList-link:last-child": {
                                            marginBottom: "4px",
                                        },
                                    }}
                                >
                                    <EmailField source="text" />
                                </SingleFieldList>
                            </Stack>

                        </ArrayField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Guest speakers
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <ArrayField source="guestSpeakers">
                            <SingleFieldList
                                sx={{
                                    "& ": {
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        flexWrap: "wrap",
                                        marginTop: "0",
                                        marginBottom: "0",
                                    },
                                    "& .RaSingleFieldList-link:last-child": {
                                        marginBottom: "4px",
                                    },
                                }}
                            >
                                <EmailField source="email" />
                            </SingleFieldList>
                        </ArrayField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Moderators
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <ArrayField source="moderators">
                            <SingleFieldList
                                sx={{
                                    "& ": {
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        flexWrap: "wrap",
                                        marginTop: "0",
                                        marginBottom: "0",
                                    },
                                    "& .RaSingleFieldList-link:last-child": {
                                        marginBottom: "4px",
                                    },

                                }}
                            >
                                <EmailField source="email" />
                            </SingleFieldList>

                        </ArrayField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Type
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Stack direction="horizontal" alignItems="center" spacing={5} >
                            <TextField source="type" />
                            <button
                                className="copy-to-clipboard-btn"
                                onClick={() => setOpenType(true)}
                            >
                                <EditIcon fontSize="inherit" />
                            </button>
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Time zone
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="timeZone" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Description text
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="descriptionText" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Author pic
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                        <ImageField source="authorPic" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Body
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="body" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Author
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="author" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Category
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="cat" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Audience authenticate
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <BooleanField source="audienceAuthenticate" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Audience Size
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <NumberField source="participants" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Estimate duration
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <FunctionField
                            source="estDuration"
                            render={record => `${record.estDuration ? record.estDuration : 0}min`}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Event duration
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <FunctionField
                            source="duration"
                            render={record => `${renderDuration(record)}`}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Audience overage quote
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="overage.audienceOverageMessage" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                        Duration overage quote
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <TextField source="overage.durationOverageMessage" />
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
    );
};

export const PostsShow = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    return (
        <Show actions={<InstanceShowActions />} title={<MainTitle />} {...props} resource="posts">
            <ThemeProvider theme={theme}>
                <TabbedShowLayout>
                    <Tab label="general">
                        <GeneralInfoTable />
                    </Tab>
                    <Tab label="Recording">
                        <Recording />
                    </Tab>
                    <Tab label="statistics">
                        <Stats />
                    </Tab>
                    <Tab label="Bill">
                        <Bill />
                    </Tab>
                    <Tab label="Activity">
                        <Streaming />
                    </Tab>
                    <Tab label="participants">
                        <Participants />
                    </Tab>
                    {!isB2B() ?
                        <Tab label="Tips">
                            <TipsFilteredList source={'id'} fieldName={'eventId'} />
                        </Tab>
                        : null
                    }
                    {!isB2B() ?
                        <Tab label="Tickets">
                            <TicketsFilteredList source={'id'} fieldName={'eventId'} />
                        </Tab>
                        : null
                    }
                    <Tab label="VMs">
                        <FanoutsFilteredList tab="posts" />
                    </Tab>
                </TabbedShowLayout>
            </ThemeProvider>
        </Show>
    );
};
